// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bf-91890-a-6-d-3-f-41-a-7-a-9-c-5-8-f-48-e-97-ab-215-js": () => import("./../../../src/pages/bf91890a-6d3f-41a7-a9c5-8f48e97ab215.js" /* webpackChunkName: "component---src-pages-bf-91890-a-6-d-3-f-41-a-7-a-9-c-5-8-f-48-e-97-ab-215-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-f-55905-ae-b-44-a-46-cc-8-be-2-d-6-f-9-d-0-aaedb-9-js": () => import("./../../../src/pages/f55905ae-b44a-46cc-8be2-d6f9d0aaedb9.js" /* webpackChunkName: "component---src-pages-f-55905-ae-b-44-a-46-cc-8-be-2-d-6-f-9-d-0-aaedb-9-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

